.k-window-titlebar {
  background-color: white;
  padding: 42px 24px 30px;
  position: relative;

  .k-window-title {
    color: black;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    padding: 0;
  }

  .k-window-titlebar-actions {
    position: absolute;
    top: 75px;
    right: 15px;
    color: black;
    opacity: 0.54;

    .k-button {
      width: 36px;
      height: 36px;
      padding: 8px;
      border-radius: 100%;
    }
  }
}

.k-window-content {
  img {
    width: 100% !important;
    max-width: initial !important;
    max-height: initial !important;
  }
}

#k-window-wrapper {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
}
