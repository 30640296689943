.CandidateVerifyEmailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  text-align: center;
}

.CandidateVerifyEmailContainer h2 {
  margin-bottom: 1rem;
}

.CandidateVerifyEmailContainer p {
  color: #666;
}
