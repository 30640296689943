.MessageItemContainer {
  display: flex;
  flex-direction: row;
}

.AlignRight {
  justify-content: flex-end;
}

.MessageItemContainer:not(:first-of-type) {
  margin-top: 8px;
}

.MessageItemContainer:not(:last-of-type) {
  margin-bottom: 8px;
}

.MessageItemHeader {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.MessageItemHeader p {
  margin: 0;
  margin-bottom: 7px;
}

.MessageItemBody {
  margin-left: 10px;
}
