.MessageContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-height: 700px;
  min-height: 100%;
}

.MessageContainerHeader {
  padding: 3px 5px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.MessageContainerHeader h6:hover {
  color: #0d6891;
}

.MessageContainerBody {
  flex: 1;
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 200px;
}

.MessageContainerBody ul {
  padding-left: 20px;
  padding-right: 20px;
}

.MessageContainerSend {
  margin: 10px;
}

.NoMessage {
  color: gray;
}

.LoaderContainer {
  display: flex;
  justify-content: center;
}
