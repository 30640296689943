.ResetPasswordTooltip {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 0.7rem;
}

.ResetPasswordTooltip svg {
  height: 18px;
  width: 18px;
  margin-right: 1rem;
}
